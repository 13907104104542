<template>
    <!-- 서브페이지 내용 -->
    <div class="con-wrap">

        <CarrotTitle title="명함 등록"></CarrotTitle>
        <div class="board">
            <div class="mt-40">
                <div>
                    <table class="table-row">
                        <colgroup>
                            <col width="180">
                            <col width="*">
                            <col width="180">
                            <col width="*">
                        </colgroup>
                        <tbody>
                            <tr>
                                <th><span class="txt-red">*</span> 고객사/이름</th>
                                <td colspan="3">
                                    <div class="bold">
                                        {{ mod.info.company_kname }}
                                        {{ mod.info.kname }} {{ mod.info.ename }}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>담당자 정보</th>
                                <td colspan="3">
                                    <div>
                                        <label v-for="(irow, i) in mod.mtype_list" :key="i"><input type="checkbox" v-model="mod.info.mtype" :value="irow.code"><span class="ml-5 mr-20"> {{ irow.name }}</span></label>
                                    </div>
                                    <div class="mt-5">
                                        <span class="mr-20">VIP</span>
                                        <label><input type="radio" v-model="mod.info.is_vip" value="Y"><span class="ml-5 mr-20"> Y</span></label>
                                        <label><input type="radio" v-model="mod.info.is_vip" value="N"><span class="ml-5 mr-20"> N</span></label>
                                    </div>
                                    <div class="mt-5">
                                        <span class="mr-20">메인 담당자</span>
                                        <label><input type="radio" v-model="mod.info.is_main" value="Y"><span class="ml-5 mr-20"> Y</span></label>
                                        <label><input type="radio" v-model="mod.info.is_main" value="N"><span class="ml-5 mr-20"> N</span></label>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>부서</th>
                                <td colspan="3">
                                    <input type="text" class="w-80per" v-model="mod.info.part">
                                </td>
                            </tr>
                            <tr>
                                <th>직급</th>
                                <td colspan="3">
                                    <input type="text" class="w-80per" v-model="mod.info.position">
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> E-mail</th>
                                <td colspan="3">
                                    <input type="text" class="w-80per" v-model="mod.info.email">
                                </td>
                            </tr>
                            <tr>
                                <th>Tel</th>
                                <td>
                                    <input type="text" class="w-100per" v-model="mod.info.tel">
                                </td>
                                <th>Mobile</th>
                                <td>
                                    <input type="text" class="w-100per" v-model="mod.info.mobile">
                                </td>
                            </tr>
                            <tr>
                                <th>Fax</th>
                                <td colspan="3">
                                    <input type="text" class="w-200px" v-model="mod.info.fax">
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 주소</th>
                                <td colspan="3">
                                    <carrot-address v-if="mod.is_loaded" v-model:zipcode.sync="mod.info.zipcode" v-model:address.sync="mod.info.addr"></carrot-address>
                                    <input type="text" v-model.trim="mod.info.addr_sub" class="w-100per mt-5" placeholder="상세주소를 입력하세요.">
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 사보발송 여부</th>
                                <td colspan="3">
                                    <label><input type="radio" v-model="mod.info.is_newsletter" value="Y"><span class="ml-5 mr-20"> 발송</span></label>
                                    <label><input type="radio" v-model="mod.info.is_newsletter" value="N"><span class="ml-5 mr-20"> 미발송</span></label>
                                </td>
                            </tr>
                            <tr>
                                <th>사보발송 주소</th>
                                <td colspan="3">
                                    <label><input type="checkbox" v-model="mod.info.is_nl_same" value="Y" @change="mod.changeNL"><span class="ml-5 mr-20"> 기본 주소와 동일</span></label><br>
                                    <div v-if="!mod.info.is_nl_same">
                                        <carrot-address v-if="mod.is_loaded" v-model:zipcode.sync="mod.info.nl_zipcode" v-model:address.sync="mod.info.nl_addr"></carrot-address>
                                        <input type="text" v-model.trim="mod.info.nl_addr_sub" class="w-100per mt-5" placeholder="상세주소를 입력하세요.">
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> DM 발송 대상</th>
                                <td colspan="3">
                                    <label><input type="radio" v-model="mod.info.is_dm" value="Y"><span class="ml-5 mr-20"> 발송</span></label>
                                    <label><input type="radio" v-model="mod.info.is_dm" value="N"><span class="ml-5 mr-20"> 미발송</span></label>
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 재직 여부</th>
                                <td colspan="3">
                                    <label><input type="radio" v-model="mod.info.is_work" value="Y"><span class="ml-5 mr-20"> 재직중</span></label>
                                    <label><input type="radio" v-model="mod.info.is_work" value="N"><span class="ml-5 mr-20"> 퇴사</span></label>
                                </td>
                            </tr>
                            <tr>
                                <th>특이사항</th>
                                <td colspan="3">
                                    <textarea class="w-100per h-100px" v-model.trim="mod.info.special_memo"></textarea>
                                </td>
                            </tr>
                            <tr>
                                <th>활성화 유무</th>
                                <td colspan="3">
                                    <label><input type="radio" v-model="mod.info.is_use" value="Y"><span class="ml-5 mr-20"> 활성화</span></label>
                                    <label><input type="radio" v-model="mod.info.is_use" value="N"><span class="ml-5 mr-20"> 비활성화</span></label>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <button @click="mod.doCancel" class="btn-default float-left mt-30">취소</button>
                    <button v-if="mod.info.is_del_able=='Y'" @click="mod.doDelete" class="btn-default float-left mt-30 ml-10">삭제</button>
                    <button @click="mod.doSubmit" class="btn-default float-right mt-30">수정</button>
                    <div class="clear"></div>
                </div>
            </div>
        </div>
    </div>
    <!-- 서브페이지 내용 끝 -->
</template>

<script>
// @ is an alias to /src
import { onMounted, reactive } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
import Swal from 'sweetalert2'
import CarrotAddress from '@/components/common/CarrotAddress.vue'


export default {
    layout:"customerManagement",
    components: {
        CarrotAddress
    },
    setup() {
        const router = new useRouter();
        const route  = new useRoute();
        const toast = useToast()

        const mod = reactive({
            mtype_list : [],

            idx : 0,
            is_loaded : false,

            info : {
            },
            mod_memo : "",

            getMtypeList : () => {
                let params = { };

                axios.get("/rest/customermgr/getMtypeList", { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        mod.mtype_list = res.data.list;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            changeNL : () => {
                console.log(mod.info.is_nl_same);
                if( mod.info.is_nl_same == true ){
                    mod.info.nl_zipcode  = mod.info.zipcode;
                    mod.info.nl_addr     = mod.info.addr;
                    mod.info.nl_addr_sub = mod.info.addr_sub;
                } else {
                    mod.info.nl_zipcode  = "";
                    mod.info.nl_addr     = "";
                    mod.info.nl_addr_sub = "";
                }
            },

            doSubmit : () => {
                let params = {
                    idx      : mod.idx,
                    mtype    : mod.info.mtype,
                    is_vip   : mod.info.is_vip,
                    is_main  : mod.info.is_main,
                    part     : mod.info.part,
                    position : mod.info.position,
                    email    : mod.info.email,
                    tel      : mod.info.tel,
                    mobile   : mod.info.mobile,
                    fax      : mod.info.fax,
                    zipcode  : mod.info.zipcode,
                    addr     : mod.info.addr,
                    addr_sub : mod.info.addr_sub,

                    is_newsletter : mod.info.is_newsletter,
                    is_nl_same    : mod.info.is_nl_same==true?"Y":"N",
                    nl_zipcode    : mod.info.nl_zipcode,
                    nl_addr       : mod.info.nl_addr,
                    nl_addr_sub   : mod.info.nl_addr_sub,

                    is_dm        : mod.info.is_dm,
                    special_memo : mod.info.special_memo,
                    is_use       : mod.info.is_use,
                    is_work      : mod.info.is_work
                };

                let re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
                if ( mod.info.email == '' || !re.test(mod.info.email) ) {
                    toast.error("올바른 이메일 주소를 입력하세요.");
                    return;
                }

                if( !mod.info.zipcode || !mod.info.addr || !mod.info.addr_sub ){
                    toast.error("필수 항목을 입력해주세요.");
                    return;
                }

                if( !mod.info.is_newsletter ){
                    toast.error("필수 항목을 입력해주세요.");
                    return;
                }

                if( !mod.info.is_dm || !mod.info.is_work){
                    toast.error("필수 항목을 입력해주세요.");
                    return;
                }

                if( !mod.info.special_memo.length ){
                    toast.error("특이사항을 입력해주세요.");
                    return;
                }

                axios.post("/rest/customermgr/modBizcard", params).then((res) => {
                    if( res.data.err == 0 ){
                        router.push({
                            name : "customerManagement-businessCardView-idx",
                            params : {
                                idx : mod.idx
                            }
                        });
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            doDelete : () => {
                Swal.fire({
                    title:'삭제하시겠습니까?',
                    showCancelButton: true
                }).then((res) => {
                    if (res.isConfirmed) {
                        let params = {
                            idx : mod.idx
                        };
                        axios.post("/rest/customermgr/delBizcard", params).then((res) => {
                            if( res.data.err == 0 ){
                                router.push({
                                    name : "customerManagement-businessCardList"
                                });
                            } else {
                                if(res.data.err_msg) toast.error(res.data.err_msg);
                            }
                        });
                    }
                });
            },

            doCancel: () => {
                router.go(-1);
            },

            doSearch: () => {
                let params = {
                    idx : mod.idx
                };

                axios.get("/rest/customermgr/getBizcardInfo", { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        mod.info = res.data;

                        mod.info.is_nl_same = mod.info.is_nl_same=="Y"?true:false;

                        mod.is_loaded = true;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            }
        });

        onMounted(() => {
            // Mounted
            mod.idx = route.params.idx?route.params.idx:0;

            if ( !mod.idx ) {
                router.back(-1);
            }

            mod.getMtypeList();

            mod.doSearch();
        });

        return {mod};
    }
}
</script>

<style lang="scss" scoped>
</style>